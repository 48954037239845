@import '../../global/common.scss';

.tag{
    display: inline-block;
    border-radius: 4.3px;
    border: solid 1px #ffffff;
    background-color: #ffffff;    
    text-align: center;
    font-size: 12px;
    padding: 0 3px;

    @media (min-width: #{$--desktop}){
        font-size: 14px;
        letter-spacing: 1.35px; 
    }
}

.nf{
    border-color: #ff9275;
    color: #ff9275;
}

.audit{
    border-color: #97dbee;
    color: #97dbee;
}