@import '../../global/common.scss';
//notebook size
//@media (min-width: #{$--desktop}){
//     font-size: 36px;            
//   }

.page_title{
  font-size: 24px;
  letter-spacing: 2.7px;
  color: #555555;
  font-weight: bold;
  margin-top: 26px;
  margin-bottom: 10px;

  @media (min-width: #{$--desktop}){
    font-size: 36px;
  }
}


.title_box{

  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
  border-bottom: 2px solid #eef0f6;

  @media (min-width: #{$--desktop}){
    padding: 20px 35px;
  }

  .title{
    font-size: 20px;
    color: #555555;

    @media (min-width: #{$--desktop}){
      font-size: 28px;
    }
  }

}

.outline_btn{
  border-radius: 10px;
  border: solid 2px #f2f3f8;
  background-color: #ffffff;
  padding: 4px 18px;
  color: #5475a2;
  font-size: 16px;
  font-family: Microsoft JhengHei;

  @media (min-width: #{$--desktop}){
    font-size: 18px;
    padding: 8px 25px;
    border-radius: 18px;
  }
}

.fill_btn{
  border-radius: 13.5px;
  background-color: #ff7e3f;
  padding: 8px 45px;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  border:none;
  font-family: Microsoft JhengHei;

  @media (min-width: #{$--desktop}){
    font-size: 20px;
    padding: 12px 60px;
    border-radius: 23.5px;
  }
}

.content{
  padding: 40px 35px;

  .form_text{
    align-self: center;
    text-align: left;
    @media  (min-width: #{$--desktop}){
        text-align: center;            
    }
  }

  .form_lable_text{
    display: inline-block!important;    
    font-size: 16px!important;
    // padding-top: 20px!important;
    color: #555555!important;

    @media (min-width: #{$--desktop}){
      font-size: 18px!important;
    }
   
  }

  .form_input{
    display: flex;
    align-items: center;
    text-align: left;
    padding: 5px 0;
  }
  
  .blue_text{
    font-size: 16px;
    letter-spacing: 1.35px;
    color: #5475a2;
    margin-top: 8px;
    margin-bottom: 4px;
    padding: 9px 0;
    @media (min-width: #{$--desktop}){
      font-size: 18px;
    }
  }

  .black_text{
    font-size: 16px;
    letter-spacing: 1.35px;
    color: #555555;
    margin-top: 8px;
    margin-bottom: 4px;
    padding: 9px 5px 9px 0;

    @media (min-width: #{$--desktop}){
      font-size: 18px;
    }
  }

}

.btn_box{
  padding-bottom: 60px;
}