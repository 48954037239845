@import "../../global/common.scss";

.menuOpen {
  transition: all 0.3s ease-in;
  position: fixed;
  z-index: 9999;
  right: 0px;
  bottom: 0px;
  top: 0px;
  left: 0px;
  position: fixed;
  align-items: center;
  touch-action: none;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  &.show {
    visibility: visible;
    opacity: 1;
  }
}

.menu {
  transition: all 0.3s ease-in;
  width: 100%;
  // height:auto;
  height: 100%;
  // height: 100vh;
  background-color: #bb2525;
  box-shadow: -3px 0px 7px 0 rgba(0, 0, 0, 0.2);
  position: fixed;
  z-index: 9999;
  overflow-y: auto;
  overflow-x: hidden;
  top: 0;
  right: -1200px;
  opacity: 0;
  display: none;
  display: inline-block;
  scrollbar-color: #cc3838 #f5f5f5;
  scrollbar-width: thin;
  @media (min-width: #{$--desktop}) {
    width: 400px;
    right: -500px;
  }

  &.show {
    display: inline-block;
    right: 0;
    opacity: 1;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
    border-radius: 6px;
  }

  &::-webkit-scrollbar {
    width: 6px;

    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cc3838;
    border-radius: 6px;
  }
}

.top {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  //text-align: right;
  padding: 10px 20px;

  .close {
    font-family: Ubuntu;
    font-size: 13px;
    line-height: 2.17;
    letter-spacing: 1.95px;
    color: #ffffff;
    display: inline-block;
    @media (min-width: #{$--desktop}) {
      font-size: 18px;
    }
  }

  @media (min-width: #{$--desktop}) {
    padding: 24px 50px;
  }
}

.photo {
  display: flex;
  align-items: center;
  justify-content: center;
  .bg {
    width: 73px;
    height: 73px;
    border-radius: 999em;
    background-color: #cf3939;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (min-width: #{$--desktop}) {
      width: 90px;
      height: 90px;
    }
    .icon {
      font-size: 73px;
      @media (min-width: #{$--desktop}) {
        font-size: 90px;
      }
    }
  }
}
//all

.form_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .item_10 {
    flex-grow: 0;
    max-width: 80%;
    flex-basis: 80%;
  }

  .item_2 {
    flex-grow: 0;
    max-width: 20%;
    flex-basis: 20%;
  }
  .item_12 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
  }
}

.vertical_c {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
}

.horizontal_c {
  display: flex;
  justify-content: center;
}

.horizontal_right {
  display: flex;
  justify-content: flex-end;
}

.py_m {
  padding-top: 17px;
  padding-bottom: 17px;
  @media (min-width: #{$--desktop}) {
    padding-top: 22px;
    padding-bottom: 22px;
  }
}

.px_l {
  padding-left: 40px;
  padding-right: 20px;
  @media (min-width: #{$--desktop}) {
    padding-left: 60px;
    padding-right: 35px;
  }
}

.mr_s {
  margin-right: 12px;
  @media (min-width: #{$--desktop}) {
    margin-right: 10px;
  }
}

.mb_s {
  margin-bottom: 5px;
}

//login
.login_layout {
  text-align: center;
  padding: 14px 20px;
  @media (min-width: #{$--desktop}) {
    padding: 17px 42px;
  }
}

.input_text {
  border-radius: 5px;
  background-color: #ffffff;
  font-family: "Tahoma";
  font-size: 18px;
  border: 0px;
  width: 250px; //190
  height: 20px;
  padding: 10px 13px;
  margin: 5px 0;
  letter-spacing: 0.8px;

  @media (min-width: #{$--desktop}) {
    width: 207px;
    height: 17px;
    padding: 11px 16px;
  }
}

.show_password_btn {
  position: relative;
  float: right;
  margin-top: -36px;
  color: #888888;
  margin-right: -28px;
  cursor: pointer;
  @media (min-width: #{$--desktop}) {
    margin-right: 0px;
  }
}

.pink_text_small {
  font-size: 13px;
  font-weight: bold;
  line-height: 1.56;
  letter-spacing: 1.2px;
  color: #ffc0c0;
  text-decoration: none;
  @media (min-width: #{$--desktop}) {
    font-size: 16px;
  }
}

.hover_color {
  &:hover {
    color: #ffffff;
  }
}

.login_button {
  text-align: center;
  margin: 5px 0;
  button {
    padding: 10px 0;
    border-radius: 23.5px;
    background-color: #cc3838;
    border: 0px;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.67;
    letter-spacing: 1.35px;
    color: #ffffff;
    font-family: Microsoft JhengHei !important;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 210.7px;
    &:hover {
      -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
    }
    &:disabled {
      background-color: #ca5a5a;
    }
  }
  .buttonProgress {
    color: #ffffff !important;
    margin-right: 5px;
  }
}

.custom_checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  & + label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
  }
  // Box hover
  &:hover + label:before {
    background-color: #ccc;
  }

  // Box checked
  &:checked + label:before {
    background: #ffffff;
  }

  &:checked + label:after {
    content: "";
    position: absolute;
    left: 6px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid #bb2525;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
}

//content

.white_text_big {
  font-size: 18px;
  line-height: 1.04;
  letter-spacing: 1.8px;
  color: #fffefe;
  @media (min-width: #{$--desktop}) {
    font-size: 24px;
  }
}

.hr {
  width: 100%;
  margin-top: 20px;
  padding-top: 17px;
  border-top: 2px solid #a71b1b;
  @media (min-width: #{$--desktop}) {
    margin-top: 15px;
    padding-top: 14px;
  }
}

.menu_content {
  min-height: 100vh;
  height: auto;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  margin-top: 34px;
  margin-bottom: 0px;
  @media (min-width: #{$--desktop}) {
    margin-top: 40px;
  }

  a,
  span {
    padding: 15px 2px 15px 30px;
    border-bottom: solid 1px #dddddd;
    font-size: 15px;
    line-height: 1.39;
    letter-spacing: 1.35px;
    text-align: left;
    color: #333333;
    display: block;
    @media (min-width: #{$--desktop}) {
      font-size: 18px;
      padding: 18px 5px 18px 38px;
    }

    &:hover {
      background-color: #dfe4e8;
      border-radius: 15px;
    }
  }
  span {
    &.group_website {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .icons_svg {
    color: #bb2525;
    padding-right: 10px;
  }

  .menu_c_sub {
    padding: 0px;
    margin: 0px;
    a,
    span {
      padding: 15px 0 15px 45px;
      font-size: 14px;
      color: #555555;
      @media (min-width: #{$--desktop}) {
        padding: 18px 0 18px 53px;
        font-size: 16px;
      }
    }
  }
}

.pointer {
  cursor: pointer;
}
