@import '../../global/common.scss';

.img{
    // width: 560px;
    height: auto;
    max-width: 90%;    
}

.title{
    font-family: Microsoft JhengHei!important;
    font-size: 24px;
    letter-spacing: 1.5px;
    text-align: center;
    color: #333333;
    @media (min-width: #{$--desktop}){
        font-size: 36px;          
    } 
}



.footer{
    display: flex;
    flex-flow:column nowrap;
    width: 100%;
    //height: 300px;
    color: #fffefe;
    font-family: Ubuntu;
    box-shadow: 0px 3px 7px 0 rgba(0, 0, 0, 0.3);
    background-color: #a41d1d;   
}

.copyright{
    flex: 1;
    -webkit-box-flex: 1;/* Safari */       
    font-size: 12px;
    line-height: 1.79;
    letter-spacing: 1.05px;
    text-align: center;  
    padding:15px 49px;
    // border-top: 1px solid #d84b4b;
   @media (min-width: #{$--desktop}){
        font-size: 14px;
    }
}