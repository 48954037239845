@import '../../global/common.scss';

.img{
    // width: 560px;
    height: auto;
    max-width: 90%;    
}

.text{
    font-family: Microsoft JhengHei!important;
    font-size: 14px;
    letter-spacing: 1.5px;
    text-align: center;
    color: #333333;
    @media (min-width: #{$--desktop}){
        font-size: 20px;          
    } 
}



.btn{
    box-shadow: 0px 2px 8px 0 rgba(0, 0, 0, 0.3);
    background-color: #ffffff;
    border: none;
    border-radius: 23.5px;
    padding:12px 33px;
    text-align: center;
    color: #ff651a;
    font-family: MicrosoftJhengHei;
    font-size: 18px;
    font-weight: bold;
    @media (min-width: #{$--desktop}){
        font-size: 20px;          
    } 
    
}