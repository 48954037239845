@import '../../global/common.scss';
//notebook size
//@media (min-width: #{$--desktop}){
//     font-size: 36px;            
//   }
.d_title_font { 
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 2.7px;
  text-align: center;
  color: #555555;
  @media (min-width: #{$--notebook}){
    font-size: 36px;            
  }
}
.d_title{
  margin-top: 28px;
  margin-bottom: 17px;
 @media (min-width: #{$--desktop}){
    margin-top: 30px;
    margin-bottom: 40px;           
  }
}

.d_subtitle_font { 
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 2.7px;
  text-align: center;
  color: #777777;
  @media (min-width: #{$--notebook}){
    font-size: 28px;            
  }
}
.d_subtitle{
  margin-bottom: 17px;
  margin-bottom: 17px;
 @media (min-width: #{$--desktop}){
    margin-top: 20px;
    margin-bottom: 20px;           
  }
}

.btn_more{
  background-color: #f4b437!important;
  color:#ffffff!important;
  margin-bottom: 10px!important;
}

.news_title{
  font-size: 18px;
  line-height: 1.38;
  letter-spacing: 1.2px;
  text-align: center;
  color: #555555;
  font-family: Microsoft JhengHei;

 @media (min-width: #{$--desktop}){
      font-size: 24px;
      line-height: 1.67;
      letter-spacing: 1.35px;
      color: #555555;
      font-family: Microsoft JhengHei;
  }
  
}

.news_title_list{
  font-size: 18px;
  line-height: 1.38;
  letter-spacing: 1.2px;
  text-align: left;
  color: #555555;
  font-family: Microsoft JhengHei;

 @media (min-width: #{$--desktop}){
      font-size: 24px;
      line-height: 1.67;
      letter-spacing: 1.35px;
      color: #555555;
      font-family: Microsoft JhengHei;
  }
  
}

.news_ref{
  padding-top: 20px;
  text-align: center;
}

.dialog_title{
  text-align: center;
  color:#bb2525;
}

.dialog_content{
  padding: 24px 16px!important;
  @media (min-width: #{$--notebook}){
    padding: 24px!important;            
  }
}

.ref{
  color: #337ab7;
  text-decoration: none;
  &:hover{
    text-decoration: underline;
  }
}

.top_news_subject{
  color: #337ab7;
  text-decoration: none;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  @media (min-width: #{$--notebook}){
    font-size: 18px;      
  }
}

.img_scale{
  transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  &:hover{
    transform: scale(1.02);
  }
}

.img{
  width: 100%!important;
  object-fit: cover!important; 
}

.date_display {
  font-size: 1em;  /* Adjust this to change size */  
 	display: inline-block;
  font-family: 'Lucida Grande', Verdana, Arial, Helvetica, sans-serif;
  font-weight: bold;
  line-height: 1em;
  width: 4.5em;
  text-align: center;
  margin-bottom: 1em;
  
  .date_display_cal {
    // box-shadow: 0 0.2em 0.3em 0 rgba(0,0,0,0.5);
    position: relative;
    
  	.date_display_month {
      display: block;
      min-height: 1.7em;
      padding: 0.4em 0 0.3em;
    	text-transform: uppercase;
      color: white;
      // text-shadow: 0 0.1em 0.1em rgba(0,0,0,0.5);
      // background: linear-gradient(to bottom, rgba(255,131,137,1) 0%,rgba(255,63,70,1) 100%);
      background-color: #bb2525;
      // box-shadow: inset 0em 0em 0.5em 0em rgba(0,0,0,0.3);
      // border: 1px solid #B14545;
      border: 1px solid #bb2525;
      border-bottom: none;
      position: relative;
      border-radius: 5px 5px 0 0;
      
      &:before, &:after {
      	content: ' ';
        display: block;
        background: white;
        width: 0.2em;
        height: 0.4em;        
        margin-top: -0.3em;
        position: absolute;
        top: 0;
        border: 1px solid rgba(0,0,0,0.4);
        // box-shadow: 0 0 0.1em 0 rgba(0,0,0,0.6);
      }
      
      &:before {
      	left: 25%;
      }
      &:after {
      	right: 25%;
      }
    }
    
    .date_display_day {
      min-height: 1.8em;
    	font-size: 1.8em;
      line-height: 1.2em;
      padding: 0.2em 0em;
      background: white;
      border: 1px solid #BAACAC;
      border-bottom-color: #555;
      border-top: none;
      border-radius: 0 0 5px 5px ;

      &.day_text{
        font-size: 1.2em;
        min-height: 2.7em;
        line-height: 2em;
      }
    }
  }
   
}