@import '../../global/common.scss';

// @mixin bg($img,$center:center,$top:top,$no:no-repeat){
//     background: url("#{$img_path}#{$img}");
//     background-position:$center $top;
//       background-repeat:$no;
//       width: 100%;
//       height: 100%;  
//    }

.header{
    z-index: 3;
    width: 100%;
    height: 50px;
    position: fixed;
    top: 0;
    left: 0;
    color: #fffefe;
    box-shadow: 0px 3px 7px 0 rgba(0, 0, 0, 0.3);
    background-color: #bb2525;       
   @media (min-width: #{$--desktop}){
        height: 80px;
    } 
}

.outer_header{    
    height: 100%;
    position: relative;
    display: flex;
    display: -webkit-flex;/* Safari */ 
    flex-flow:row nowrap;
    align-items:center;
    padding-left: 20px;
    padding-right: 20px;
   @media (min-width: #{$--desktop}){
        padding-left: 50px;
        padding-right: 50px;
    }
}

.logo{
    flex:1;
    -webkit-box-flex: 1;/* Safari */ 
    position: absolute;
    top:0;
    left: 20px;
    width: 100px;
    height: 55px;
    padding: 2.5px 12px; 
    border-radius: 0px 0px 6px 6px;
    box-shadow: 0px 3px 7px 0 rgba(0, 0, 0, 0.3);
    background-color: #ffffff;    
   @media (min-width: #{$--desktop}){
        left: 50px;
        width: 170px;
        height: 90px;
        padding: 5px 30px;
    }
    .logo_img{
        // @include bg("torch_logo.png");
        background: url("#{$img_path}#{'torch_logo.png'}");
        width: 100%;
        height: 100%;        
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
}

.nav{    
    flex:1;
    -webkit-box-flex: 1;/* Safari */ 
    display: flex;
    display: -webkit-flex;/* Safari */ 
    flex-flow:row nowrap;
    align-items:center;
    justify-content: flex-end;

    .profile{        
        padding-right: 12px;
       @media (min-width: #{$--desktop}){
            padding-right: 20px;
        }
    }

    .login_text{
        display: none;
        font-family: MicrosoftJhengHei;
        font-size: 18px;
        line-height: 1.39;
        letter-spacing: 1.35px;        
        padding-right: 40px;
       @media (min-width: #{$--desktop}){
            display: block;
        }
    }
}

.pointer{
    cursor: pointer;
}


