@import "../../global/common.scss";
//notebook size
//@media (min-width: #{$--desktop}){
//     font-size: 36px;
//   }

.card {
  height: 100%;
  position: relative;
  display: flex;
  display: -webkit-flex; /* Safari */
  flex-direction: column;
  flex-wrap: wrap;
}

.card_content {
  width: 100%;
  flex: 1;
  -webkit-box-flex: 1; /* Safari*/
  overflow: hidden;
  padding: 10px 30px;

  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 50% 50%;
  // display: flex;
  // flex-direction: column;
  // flex-wrap: nowrap;

  @media (min-width: #{$--desktop}) {
    grid-template-columns: 60% 40%;
    grid-template-rows: 100%;
    // flex-direction: row;
    // flex-wrap: wrap;
  }
}

.f_all {
  // flex-basis: 100%;
  grid-row-start: 1;
  grid-row-end: span 2;
  grid-column-start: 1;
  grid-column-end: span 2;
}

.f_left {
  // flex-basis: 60%;
}

.f_right {
  // flex-basis: 40%;
  display: flex;
  justify-content: center;
  align-items: center;

  .info_box {
    padding: 15px;
    border: solid 2px #f2f3f8;
    border-radius: 5px;
    font-size: 16px;

    @media (min-width: #{$--desktop}) {
      padding: 20px;
      font-size: 18px;
    }
    .text {
      color: #555555;
      padding: 3px 0;
    }

    .note {
      padding-top: 15px;
      color: #90a4ae;

      @media (min-width: #{$--desktop}) {
        padding-top: 20px;
      }
    }

    .content_box {
      padding-top: 9px;

      @media (min-width: #{$--desktop}) {
        padding-top: 20px;
      }

      .c_box {
        display: inline-block;
        text-align: center;
        color: #555555;
        padding: 18px 20px;
        font-size: 16px;

        @media (min-width: #{$--desktop}) {
          padding: 18px 20px;
          font-size: 18px;
        }

        &.box_left {
          margin-right: 5px;
          background-color: #e7f9f2; //#fffaf4

          @media (min-width: #{$--desktop}) {
            margin-right: 10px;
          }

          .count {
            font-size: 21px;
            color: #62d6b1; //#ff9f23
            padding-right: 3px;

            @media (min-width: #{$--desktop}) {
              font-size: 28px;
            }
          }
        }
        &.box_right {
          margin-left: 5px;
          background-color: #fffaf4; //#f6f7ff;

          @media (min-width: #{$--desktop}) {
            margin-left: 10px;
          }

          .count {
            font-size: 21px;
            color: #ff9f23; //#7c9eec;
            padding-right: 3px;

            @media (min-width: #{$--desktop}) {
              font-size: 28px;
            }
          }
        }
      }
    }
  }
}
