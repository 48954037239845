@import '../../../global/common.scss';
//notebook size
//@media (min-width: #{$--desktop}){
//     font-size: 36px;            
//   }

.item_layout{
    width: 100%;
    height: 70px;
    border-left-style : solid ;
    border-left-width: 3px;    
    background-color: #ffffff;    
    padding: 5px 10px;    
    text-align: left;
    margin: 9px 0;    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap ;
    justify-content: space-between;
    align-items: stretch;   
    &:hover{
    background-color: #f5f9fc;
    }
    
    &.prm{
        border-left-color: #ff954f;
    }
    &.ins{
        border-left-color: #7c9eec;
        
    }
    &.tu{        
        border-left-color: #62d6b1;
    }

    @media (min-width: #{$--desktop}){
        height: 70px; 
        padding: 10px 20px 10px 10px; 
    }
}

.front{
    flex-basis: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap ;
    justify-content: flex-start;
    align-items: stretch;
    // cursor: pointer;
    .state{
        display: flex;
        align-items: center;
        margin-right: 10px;        
        @media (min-width: #{$--desktop}){
            margin-right: 20px;
        }

        .done{
            background-color:#ffc961!important;
        }

        .warning{
            background-color:#bb2525!important;
        }

        .success{              
            background-color:#62d6b1!important;
        }

        .member_in_org_icon{
            font-size: 1rem;  
        }
    }

    .info{
        font-size: 16px;
        letter-spacing: 1.35px;               
        .role{            
            padding-bottom: 7px;
            color: #555555;
        }
        .name{                        
            color: #5475a2;            
        }
    }
}

.back{
    flex-basis: 10%;
    align-self: center;
}

.top{
    display: flex;
    flex-wrap:wrap;
    flex-direction:row;
    justify-content: space-between;
    padding-bottom: 0px;

    @media (min-width: #{$--desktop}){
        padding-bottom: 5px;
    }

}

.title{
    font-size: 16px;  
    letter-spacing: 1.2px;
    text-align: left;
    color: #555555;
    @media (min-width: #{$--desktop}){
        font-size: 18px;       
        letter-spacing: 1.35px;        
    }
}

.ellipsis{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 160px;
    // max-width:50%;
   @media (min-width: #{$--desktop}){
        max-width: 220px;
        // width:65%;          
    }
}


.datetime{
    display: inline-block;
    font-size: 15px;  
    letter-spacing: 1.2px;
    text-align: left;
    color: #888888;
   @media (min-width: #{$--desktop}){
        font-size: 16px;       
        letter-spacing: 1.35px;        
    }
}

.menu{
    background-color: #6496db;
}