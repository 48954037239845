/* RWD */
@custom-media --mobile (min-width >= 480px);
@custom-media --tablet-30 (min-width >= 480px);
@custom-media --tablet-40 (min-width >= 640px);
@custom-media --tablet (min-width >= 768px);
@custom-media --notebook (min-width >= 1024px);
@custom-media --desktop (min-width >= 1200px);

// Small tablets and large smartphones (landscape view)
$--mobile: 576px;

// Small tablets (portrait view)
$--tablet: 768px;

// Tablets and small desktops
$--notebook: 1024px;

// Large tablets and desktops
$--desktop: 1200px;
