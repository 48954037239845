@import '../../../global/common.scss';
//notebook size
//@media (min-width: #{$--desktop}){
//     font-size: 36px;            
//   }

.item_layout{
    width: 100%;
    height: 70px;
    border-left-style : solid ;
    border-left-width: 3px;    
    background-color: #ffffff;    
    padding: 5px 10px;    
    text-align: left;
    margin: 10px 0;    
    
    

    &:hover{
    background-color: #f5f9fc;
    }
    
    &.new_friend{
        border-left-color: #ff954f;
        cursor: pointer;
    }
    &.member{
        border-left-color: #7c9eec;
        cursor: pointer;
        
    }
    &.account{        
        border-left-color: #62d6b1;
        cursor: pointer;
    }
    &.padding{        
        border-left-color: #e0e0e0;
    }

   @media (min-width: #{$--desktop}){
        height: 70px; 
        padding: 10px 20px; 
    }
}

.top{
    display: flex;
    flex-wrap:wrap;
    flex-direction:row;
    justify-content: space-between;
    padding-bottom: 0px;

   @media (min-width: #{$--desktop}){
        // padding-bottom: 5px;
    }

}

.audit_title{
    font-size: 16px;  
    letter-spacing: 1.2px;
    text-align: left;
    color: #555555;

    &.padding{        
        color:#aaaaaa;
    }

   @media (min-width: #{$--desktop}){
        font-size: 18px;       
        letter-spacing: 1.35px;        
    }
}

.dim_name{
    font-size: 16px;  
    letter-spacing: 1.2px;
    text-align: left;
    color: #5475a2;

   @media (min-width: #{$--desktop}){
        font-size: 18px;       
        letter-spacing: 1.35px;        
    }
    
}

.ellipsis{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 255px;
    // width:95%;
   @media (min-width: #{$--desktop}){
        // max-width: 200px;
        max-width:65%;          
    }
}


.datetime{
    display: inline-block;
    font-size: 15px;  
    letter-spacing: 1.2px;
    text-align: left;
    color: #888888;
   @media (min-width: #{$--desktop}){
        font-size: 16px;       
        letter-spacing: 1.35px;        
    }
}