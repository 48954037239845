@import '../../../global/common.scss';
//notebook size
//@media (min-width: #{$--desktop}){
//     font-size: 36px;            
//   }

.item_layout{
    width: 100%;
    height: 130px;   
    background-color: #ffffff;    
    // padding: 5px 10px;    
    text-align: left;
    margin: 5px 0;    
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap ;
    justify-content: space-between;
    align-items: stretch;   

   @media (min-width: #{$--desktop}){
        height: 70px; 
        flex-direction: row;
        flex-wrap: wrap ;
        align-items: center;
        margin: 9px 0; 
        // padding: 10px 20px; 
    }

    &:hover{
        background-color: #f5f9fc;
    }
   
}

.info{
    width: 80%;
}

.text_normal{
    font-size: 15px;
    letter-spacing: 1.2px; 
    padding-right: 10px;

   @media (min-width: #{$--desktop}){
        font-size: 16px;
        letter-spacing: 1.35px; 
    }

    &.blue{
        color: #5475a2;
    }

    &.black{
        color: #555555;
    }
}

.front{
    flex-basis: 75%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap ;
    justify-content: flex-start;
    align-items: stretch;
    cursor: pointer;

   @media (min-width: #{$--desktop}){
        flex-wrap: wrap ;
    }

    .state{
        margin-right: 10px;

        @media screen and (min-width: 576px){
            margin-right: 10px;
        }
    }
    
   
    .screen_display_b{
        display: block;
        margin: 3px 3px 3px 0;

       @media (min-width: #{$--desktop}){
            display: inline;
            margin: 0 0px 0 0;
        }
    }
}

.back{
    flex-basis: 25%;
    display: flex;
    align-items: center;
    justify-content: space-between;

   @media (min-width: #{$--desktop}){
        justify-content: space-around;
    }

    .follow_info{
        font-size: 14px;
        margin-right: 20px;  
        margin-left: 52px;        

        .g_light{
            color: #abfa92;
        }

        .y_light{
            color: #ffe5a7;
        }

        .disable{
            color: #cbcbcb;
        }

       @media (min-width: #{$--desktop}){
            font-size: 16px;
            margin-left: 0px;
            margin-right: 0px;
        }     
        
    }
}

.d_f{
    display: flex;
    align-items: center;
    margin: 5px 0;  
}


.ellipsis{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    // max-width: 255px;
    // width:95%;
   @media (min-width: #{$--desktop}){
        // max-width: 270px;
        // width:95%;          
    }
}

a{
    text-decoration:none;
}

.tag{
    display: inline-block;
    border-radius: 4.3px;
    border: solid 1px #ffffff;
    background-color: #ffffff;    
    text-align: center;
    font-size: 12px;
    padding: 0 3px;

    @media (min-width: #{$--desktop}){
        font-size: 14px;
        letter-spacing: 1.35px; 
    }
}

.tag_1{
    border-color: #ff9275;
    color: #ff9275;
}

.tag_2{
    border-color: #97dbee;
    color: #97dbee;
}

