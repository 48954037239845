@import "../../global/common.scss";

.d_title_font {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 2.7px;
  text-align: center;
  color: #555555;
  @media (min-width: #{$--notebook}) {
    font-size: 36px;
  }
}
.d_title {
  margin-top: 28px;
  margin-bottom: 17px;
  @media (min-width: #{$--desktop}) {
    margin-top: 30px;
    margin-bottom: 40px;
  }
}

.card_setting {
  height: 550px;
  min-height: 550px;
  // max-height: 550px;

  &.height_m {
    height: 700px;

    @media (min-width: #{$--desktop}) {
      height: 550px;
    }
  }

  &.height_l {
    height: 900px;

    @media (min-width: #{$--desktop}) {
      height: 550px;
    }
  }

  &.auto_height {
    height: auto;
    @media (min-width: #{$--desktop}) {
      height: 550px;
    }
  }
}

.card_content {
  height: 100%;
  position: relative;
}
