@import '../../global/common.scss';
//notebook size
//@media (min-width: #{$--desktop}){
//     font-size: 36px;            
//   }
tr, th, td{
  font-family: Microsoft JhengHei!important;
}

th{
  font-weight: bold!important;
}

.d_title_font { 
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 2.7px;
  text-align: center;
  color: #555555;
  @media (min-width: #{$--notebook}){
    font-size: 36px;            
  }
}
.d_title{
  margin-top: 28px;
  margin-bottom: 17px;
 @media (min-width: #{$--desktop}){
    margin-top: 30px;
    margin-bottom: 40px;           
  }
}

.d_subtitle_font { 
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 2.7px;
  text-align: center;
  color: #777777;
  @media (min-width: #{$--notebook}){
    font-size: 28px;            
  }
}
.d_subtitle{
  margin-bottom: 17px;
  margin-bottom: 17px;
 @media (min-width: #{$--desktop}){
    margin-top: 20px;
    margin-bottom: 20px;           
  }
}

.font_size{
  font-size: 14px;
  margin: auto 0; 
  @media (min-width: #{$--notebook}){
    font-size: 16px;            
  }
}

.body{
  width: 100%;
  margin: 0 auto;
  @media (min-width: #{$--notebook}){
    width: 50%;            
  }
}

// .expansion_panel{
//   margin: 15px 0;
//   @media (min-width: #{$--notebook}){
//     margin: 20px 0;        
//   }
// }