@import "../../../global/common.scss";
//notebook size
//@media (min-width: #{$--desktop}){
//     font-size: 36px;
//   }

.footer_box {
  width: 100%;
  min-height: 70px;
  border-top: 2px solid #f2f3f8;
  padding: 0 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  &.align_right {
    justify-content: flex-end;
  }
}

.page_text {
  font-size: 15px;
  line-height: 1.67;
  letter-spacing: 1.35px;
  text-align: left;
  color: #888888;
  @media (min-width: #{$--desktop}) {
    font-size: 18px;
  }
  .cur {
    font-size: 20px;
    letter-spacing: 1.8px;
    @media (min-width: #{$--desktop}) {
      font-size: 24px;
    }
  }
}

.page_icon {
  width: 33px;
  height: 33px;
  border-radius: 50%;
  margin: 0 5px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media (min-width: #{$--desktop}) {
    width: 42px;
    height: 42px;
  }

  &.back_icon {
    color: #ffffff;
    background-color: #ffc961;
    border: solid 2px #ffc961;
    &:hover{
      background-color: #eeba58;
    }
  }
  &.next_icon {
    border: solid 2px #dedede;
    background-color: #ffffff;
    color: #888888;
    &:hover{
      background-color: #e7e4e4;
    }
  }
  &.last_icon {
    border: solid 2px #dedede;
    background-color: #ffffff;
    color: #ffc961;
  }
}
