@import '../../global/common.scss';
//notebook size
//@media (min-width: #{$--desktop}){
//     font-size: 36px;            
//   }
.card{
    height: 100%;
    position: relative;
    display: flex;
    display: -webkit-flex;/* Safari */ 
    flex-direction: column;
    flex-wrap:wrap;
  }
  

.card_content{
    width: 100%;
    flex: 1;  
    -webkit-box-flex: 1;/* Safari*/
    overflow:auto;
    padding: 11px 30px;    
    scrollbar-color: #d4d4d4 #f2f3f8;
    scrollbar-width: thin;

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.062);
        background-color:#f2f3f8 ;
        border-radius: 6px;
    }
    
    &::-webkit-scrollbar {
        width: 6px;
        
        background-color: #f2f3f8;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color:   #d4d4d4;
        border-radius: 6px;
    }
    
}

.month{
    padding-top: 10px;
    font-size: 21px;
    letter-spacing: 1.58px;
    text-align: left;
    color: #ff9f23;
}

.day_item{
    border: solid 2px #f2f3f8;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 7px;
    font-size: 16px;
    letter-spacing: 1.2px;
    text-align: left;
    color: #333333;
    margin: 5px 0;

    .item{
        display: inline-block;
    }

    .week{
        padding-right: 15px;
    }
    .date{    
        width: 30px;
        height: 30px;
        border-radius: 15px;
        background-color: #ffffff;
        color: #333333;
        display: inline-flex;
        justify-content: center;
        align-items: center;    
        margin-right: 20px;   
        
        &.highlight{
            background-color: #ffc961;
            color: #ffffff;

        }
    }
    
}
  