@import '../../../global/common.scss';
//notebook size
//@media (min-width: #{$--desktop}){
//     font-size: 36px;            
//   }

.item_layout{
    width: 100%;
    height: 80px;
    border: solid 1px #f2f3f8;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 10px 13px;    
    text-align: left;
    margin: 9px 0;    
    min-height: 80px;
    cursor: pointer;
     &:hover{
        background-color: #f5f9fc;
     }
}

.top{
    display: flex;
    flex-wrap:wrap;
    flex-direction:row;
    justify-content: space-between;
    padding-bottom: 5px;

}

.tag{
    display: inline-block;
    padding: 5px 5px;
    color: #ffffff;
    border-radius: 5px;
    font-size: 16px;

    &.prm{
        background-color: #ff954f;
    }
    &.ins{
        background-color: #7c9eec;
    }
    &.tu{        
        background-color: #62d6b1;
    }
}

.dialog_title{
    &.prm{
        color: #ff954f;
    }
    &.ins{
        color: #7c9eec;
    }
    &.tu{        
        color: #62d6b1;
    }
}

.news_title{
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: 1.2px;
    text-align: left;
    color: #555555;

   @media (min-width: #{$--desktop}){
        font-size: 18px;
        line-height: 1.67;
        letter-spacing: 1.35px;
        color: #555555;
    }
    
}

.ellipsis{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 255px;
    width:95%;
   @media (min-width: #{$--desktop}){
        max-width: 380px;
        width:95%;          
    }
}

.news_ref{
    padding-top: 20px;
    text-align: center;
}

.datetime{
    display: inline-block;
}