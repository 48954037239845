@import '../../global/common.scss';
//notebook size
//@media (min-width: #{$--desktop}){
//     font-size: 36px;            
//   }
.card{
    height: 100%;
    position: relative;
    display: flex;
    display: -webkit-flex;/* Safari */ 
    flex-direction: column;
    flex-wrap:wrap;
  }
  

.card_content{
    width: 100%;
    flex: 1;  
    -webkit-box-flex: 1;/* Safari*/
    overflow:hidden;
    padding: 11px 30px;    
    
}
  