@import "../../global/common.scss";
//notebook size
//@media (min-width: #{$--desktop}){
//     font-size: 36px;
//   }

.card {
  height: 100%;
  position: relative;
  display: flex;
  display: -webkit-flex; /* Safari */
  flex-direction: column;
  flex-wrap: wrap;
}

.card_content {
  width: 100%;
  flex: 1;
  -webkit-box-flex: 1; /* Safari */
  overflow: hidden;
  padding: 10px 30px;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.photo_box {
  margin: 6px 0 10px 0;
  max-height: 60%;
  // max-height: 180px;
  // height: 180px;
  width: 100%;
  @media (min-width: #{$--desktop}) {
    margin: 16px 0 10px 0;
    // max-height: 250px;
    // height: 290px;
  }
}

.cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.article {
  padding-top: 5px;
  // height: 40%;
  @media (min-width: #{$--desktop}) {
    padding-top: 10px;
  }
  .article_title {
    font-size: 18px;
    line-height: 1.04;
    letter-spacing: 1.8px;
    text-align: left;
    color: #6f8aaf;
    padding-bottom: 15px;
    // height:30%;
    @media (min-width: #{$--desktop}) {
      font-size: 24px;
    }
  }

  .article_content {
    font-size: 16px;
    line-height: 1.56;
    letter-spacing: 1.2px;
    text-align: left;
    color: #888888;
    // height:70%;
    max-height: 170px;
    overflow: auto;
    white-space: pre-wrap;
    @media (min-width: #{$--desktop}) {
      max-height: 93px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.062);
      background-color: #f2f3f8;
      border-radius: 6px;
    }

    &::-webkit-scrollbar {
      width: 6px;

      background-color: #f2f3f8;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d4d4d4;
      border-radius: 6px;
    }
  }
}
