@import "../../global/common.scss";
//notebook size
//@media (min-width: #{$--desktop}){
//     font-size: 36px;
//   }

.card {
  height: 100%;
  position: relative;
  display: flex;
  display: -webkit-flex; /* Safari */
  flex-direction: column;
  flex-wrap: wrap;
}

.card_content {
  width: 100%;
  flex: 1;
  -webkit-box-flex: 1; /* Safari*/
  overflow: hidden;
  padding: 10px 30px;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: center;

  &.list_mode {
    overflow: auto;
    scroll-padding-bottom: 11px;
    padding: 11px 30px;
    scrollbar-color: #d4d4d4 #f2f3f8;
    scrollbar-width: thin;
    flex-wrap: nowrap;
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.062);
      background-color: #f2f3f8;
      border-radius: 6px;
    }

    &::-webkit-scrollbar {
      width: 6px;

      background-color: #f2f3f8;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d4d4d4;
      border-radius: 6px;
    }

    .img {
      width: 100%;
      height: auto;
    }
  }
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: #ff9f23;
  padding-bottom: 10px;
  @media (min-width: #{$--desktop}) {
    font-size: 28px;
  }
}

.sub_title {
  font-size: 16px;
  color: #555555;
}

.note {
  color: #90a4ae;
  font-size: 14px;
  padding-bottom: 10px;
  @media (min-width: #{$--desktop}) {
    font-size: 16px;
  }
}

.stutas {
  font-size: 24px;
  font-weight: bold;
  color: #ff9f23;
  @media (min-width: #{$--desktop}) {
    font-size: 28px;
  }
}

.btn {
  padding: 10px 50px;
  border-radius: 23.5px;
  font-size: 18px;
  font-family: Microsoft JhengHei;
  transition: 0.3s;

  &.complete {
    border: solid 1px #dfdfdf;
    background-color: #ffffff;
    color: #ff9f23;
  }

  &.delay {
    background-color: #ff7e3f;
    border: none;
    color: #ffffff;
  }

  &.notyet {
    background-color: #ff5e48;
    border: none;
    color: #ffffff;
  }
  &:hover {
    $c: #dfdfdf;
    $d: #ff7e3f;
    $n: #ff5e48;
    &.complete {
      box-shadow: 0 5px 5px -5px rgba($c, 0.15), 0 10px 10px -5px rgba($c, 0.15),
        0 15px 15px -5px rgba($c, 0.15), 0 20px 20px -5px rgba($c, 0.15);
      transform: translateY(-2px);
    }
    &.delay {
      box-shadow: 0 5px 5px -5px rgba($d, 0.15), 0 10px 10px -5px rgba($d, 0.15),
        0 15px 15px -5px rgba($d, 0.15), 0 20px 20px -5px rgba($d, 0.15);
      transform: translateY(-2px);
    }
    &.notyet {
      box-shadow: 0 5px 5px -5px rgba($n, 0.15), 0 10px 10px -5px rgba($n, 0.15),
        0 15px 15px -5px rgba($n, 0.15), 0 20px 20px -5px rgba($n, 0.15);
      transform: translateY(-2px);
    }
  }
}

.pie_img {
  position: absolute;
  left: -50px;
  top: -40px;
  width: 100px;
  height: auto;
}

.org_content {
  padding-top: 10px;
  .state_text {
    border-radius: 5px;
    padding: 7px;
    font-size: 16px;
    letter-spacing: 1.2px;
    margin: 5px 0;
    color: #ffffff;
  }
  .state_text_v1 {
    font-size: 18px;
    letter-spacing: 1.58px;
    text-align: left;
    &.c_orange {
      color: #ff9f23 !important;
    }
    &.c_blue {
      color: #7c9eec !important;
    }
    &.c_mix {
      color: #e28b8b !important;
    }
  }
  .item {
    border: solid 2px #f2f3f8;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 7px;
    font-size: 16px;
    letter-spacing: 1.2px;
    color: #333333;
  }

  .b_orange {
    border-color: #ff9f23 !important;
  }

  .b_blue {
    border-color: #7c9eec !important;
  }

  .b_mix {
    border-color: #cd4141 !important;
  }
}

.success_icon {
  font-size: 10rem !important;
  color: #50d6a5;
}
