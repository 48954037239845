@import '../../../global/common.scss';
//notebook size
//@media (min-width: #{$--desktop}){
//     font-size: 36px;            
//   }
.header{ 
    width: 100%;
    padding: 10px 0;      
    border-bottom: 2px solid #f2f3f8;    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:space-between ;

   @media (min-width: #{$--desktop}){
        flex-direction: row;              
        padding: 10px 30px; 
    }

}

.title_box{     
    width: 100%;
    flex-basis: 75%;  
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 30px 10px 30px; 

   @media (min-width: #{$--desktop}){          
        padding: 0;   
    }

    .icon_bk{       
        display: flex;
        align-items: center;
        justify-content: center;
        width: 37px;
        height: 37px;
        border-radius: 50%;
        box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.2);
        background-color: #f5f9fc;
        color: #5475a2;
       @media (min-width: #{$--desktop}){
            width: 55px;
            height: 55px;          
        }
    }

    .icon_bk_add{       
        color: #ffc961;
        background-color: #fff;
        border: 1px solid #ddd;
        margin-left: 10px;
        box-shadow: none;

    }


    .title{
        font-size: 20px;
        font-weight: bold;
        line-height: 1.07;
        letter-spacing: 2.1px;
        text-align: left;
        color: #555555;

        display: inline-flex;
        align-items: center;
        margin-left: 24px;
       @media (min-width: #{$--desktop}){
            font-size: 28px;          
        }
    }
}

.btn_box{
    width: 100%;      
    flex-basis: 25%;
    text-align: center;
    border-top: 2px solid #f2f3f8;
    padding: 10px 30px 0 30px;
   @media (min-width: #{$--desktop}){
        text-align: right;
        border-top: none;       
        padding: 0;  
    }
    .btn_approved{
        display: inline-block;
        padding: 5px 3px;
        width: 90px;    
        border-radius: 18.5px;
        background-color: #ffc961;
        font-size: 18px;
        letter-spacing: 1.58px;
        text-align: center;
        color: #ffffff;
        text-decoration: none;
       @media (min-width: #{$--desktop}){
            width: 106px;    
            font-size: 21px;      
        }
    }    

}

.org_list_box{
    width: 100%;    
    // flex-basis: 50%;  
    border-top: 2px solid #f2f3f8;
    padding: 10px 30px 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   @media (min-width: #{$--desktop}){
        flex-direction: row;
        justify-content: flex-end;
        border-top: none;       
        padding: 0;  
    }   

}

.text_normal{
    font-size: 15px;
    font-weight: 400;
}

//iphone5
.none_text{
    display: block;
    @media (max-width: 320px){
        display: none;
    }    
}







