@import '../../global/common.scss';
//notebook size
//@media (min-width: #{$--desktop}){
//     font-size: 36px;            
//   }

.vertical_c{
    display: flex;
    align-items: center;
    flex-direction: column;
    
    @media (min-width: #{$--desktop}){
        flex-direction: row;           
    }

}

.uplv{
    display: block;
    padding-bottom: 5px; 
   @media (min-width: #{$--desktop}){
        padding-bottom: 0px; 
        display: inline-block;          
    }

    .uplv_item{
        display: inline-block;
        padding: 0 12px;
        font-size: 15px;
        letter-spacing: 1.35px;
       @media (min-width: #{$--desktop}){
            font-size: 18px;          
        }

        &.lv1{
            font-weight: bold;
            color: #5475a2;
        }
        &.lv2{
            color: #5475a2;
        }
    }
    
}