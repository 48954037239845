@import '../../global/common.scss';

.footer{
    display: flex;
    flex-flow:column nowrap;
    width: 100%;
    //height: 300px;
    color: #fffefe;
    font-family: Ubuntu;
    box-shadow: 0px 3px 7px 0 rgba(0, 0, 0, 0.3);
    background-color: #a41d1d;       
   @media (min-width: #{$--desktop}){
        min-height: 165px;
        
    } 
}

.outer_footer{    
    flex:4;
    display: flex;
    -webkit-box-flex: 4;/* Safari */
    display: -webkit-flex;/* Safari */
    flex-flow:column nowrap;
    align-items:center;
    justify-content:center;    
    padding: 20px 22px 0 22px;
   @media (min-width: #{$--desktop}){        
        flex-flow:row wrap;
        justify-content: flex-start;  
        padding: 0 52px;      
    }
}

.logo{            
    width: 88.5px;
    height: 49px;   
    margin-right: 0px;
    margin-bottom: 20px;    
   @media (min-width: #{$--desktop}){        
        width: 108px;
        height: 60px;
        margin-right: 27px;
        margin-bottom: 0px;
    }
    .logo_img{
        background: url("#{$img_path}#{'torch_LOGO_w.png'}");
        width: 100%;
        height: 100%;        
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
}

.info{    
    flex:1;
    -webkit-box-flex: 1;/* Safari */  
    font-size: 14px;
    line-height: 1.56;    
    text-align: left;
    padding-bottom: 20px;
   @media (min-width: #{$--desktop}){ 
        flex:4;
        -webkit-box-flex: 4;/* Safari */      
        font-size: 16px;
        padding-bottom: 0px;
    }
    span{
        display: block;
    }
}

.social_media{
    flex: 1;
   -webkit-box-flex: 1;/* Safari */
    display: flex;
    display: -webkit-flex;/* Safari */
    flex-flow:row nowrap;
    align-items:center;
    justify-content: flex-end;
    padding-bottom: 20px;
   @media (min-width: #{$--desktop}){ 
        flex: 2;
        -webkit-box-flex: 2;/* Safari */   
        padding-bottom: 0px;
    }
    .item{
        //display: inline-block;
        border: 2px solid #d84b4b;
        border-radius: 50%;
        margin-left:20px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 46px;
        height: 46px;
        .svg{
            fill: #ffccc7;
            width: 70%;
            height: 70%;
        }

        &:hover{
            background-color: #eb5959;
            .svg{
                fill:white;
            }
        }
        // img{
        //     padding:7px;
        //     filter:invert(85%) sepia(3%) saturate(2878%) hue-rotate(315deg) brightness(98%) contrast(108%);
        //     //invert(81%) sepia(70%) saturate(534%) hue-rotate(295deg) brightness(110%) contrast(123%); 
        //     //color: #ffccc7;
        // }                                       
    }
}

.copyright{
    flex: 1;
    -webkit-box-flex: 1;/* Safari */       
    font-size: 12px;
    line-height: 1.79;
    letter-spacing: 1.05px;
    text-align: center;  
    padding:15px 49px;
    border-top: 1px solid #d84b4b;
   @media (min-width: #{$--desktop}){
        font-size: 14px;
    }
}
