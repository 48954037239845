@import "../../global/common.scss";
//notebook size
//@media (min-width: #{$--desktop}){
//     font-size: 36px;
//   }

.card {
  height: 100%;
  position: relative;
  display: flex;
  display: -webkit-flex; /* Safari */
  flex-direction: column;
  flex-wrap: wrap;
}

.card_content {
  width: 100%;
  flex: 1;
  -webkit-box-flex: 1; /* Safari*/
  overflow: hidden;
  padding: 11px 30px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: #{$--desktop}) {
    flex-direction: column;
  }

  .helf {
    width: 100%;

    @media (min-width: #{$--desktop}) {
      width: 45%;
    }
  }
}

.step_btn {
  cursor: pointer;
}

.step_root {
  color: #d9d9d9;
}
.step_active {
  color: #d9d9d9;
  // color: #f59702;
}
.step_completed {
  color: #d9d9d9;
  // color:#ffc961;
}
.step_hasmember {
  color: #f59702;
}

.popover {
  background-color: #5475a2;
  color: #ffffff;
  text-align: center;

  .cg_people_count {
    font-size: 18px;
    border-bottom: 1px solid #385f94;
    padding-bottom: 8px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cg_member_content {
    padding-top: 8px;
    padding-left: 20px;
    padding-right: 20px;

    .btn_more {
      padding: 7px 22px;
      box-shadow: 0px 2px 0 0 #c48305;
      background-color: #f4b437;
      color: #ffffff;
      border: none;
    }
  }
}

.dialog_title {
  background-color: #fbc151;
  color: white;
  text-align: center;

  .title_text {
    font-size: 24px;
    @media (min-width: #{$--desktop}) {
      font-size: 30px;
    }
  }
}

.dialog_subtitle {
  font-size: 20px;
  color: #f19a29;
  text-align: center;

  @media (min-width: #{$--desktop}) {
    font-size: 28px;
  }
}

.dialog_content {
  text-align: center;
}

.dialog_btn {
  padding: 10px 40px !important;
  text-align: center;
  border: none !important;
  border-radius: 23.5px !important;
  background-color: #f97a25 !important;
  color: white !important;
  font-size: 20px;
  &:hover {
    box-shadow: 4px 4px 6px #21212161;
  }
}

.timeline {
  display: flex;
  padding: 0;
  margin: 0;

  .timeline_item {
    cursor: pointer;
    list-style: none;
    display: inline-block;
    // width: 220px;
    width: 20%;

    &:nth-child(1) {
      width: 10%;
    }
    &:nth-last-child(1) {
      width: 10%;
    }
    // &.slide {
    //   width: 10%;
    // }

    .item_wrapper {
      color: #555555;
    }

    .item_icon {
      padding-bottom: 16px;
      border-bottom: 4px solid #efefee;
      position: relative;
      text-align: center;
      color: #888888;

      &.first {
        text-align: left;
      }

      &.last {
        text-align: right;
      }

      &.light {
        color: #ffc961;

        &:hover {
          color: #ff8244;
        }
      }

      .item_big_dots {
        position: absolute;
        left: 50%;
        margin-left: -14px;
        bottom: -14px;
        background-color: #efefee;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        text-align: center;
        vertical-align: middle;

        &.first {
          left: 14px;
        }

        &.last {
          right: 0;
          left: inherit;
        }

        .dots {
          position: absolute;
          left: 0;
          margin-left: 5px;
          bottom: 5px;
          background-color: #888888;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          text-align: center;
          vertical-align: middle;

          &.light {
            background-color: #ffc961;

            &:hover {
              background-color: #ff8244;
            }
          }
        }
      }
    }

    .item_text {
      margin-top: 18px;
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      padding: 0 10px;

      &.first {
        text-align: left;
        padding-left: 0;
      }

      &.last {
        text-align: right;
        padding-right: 0;
      }
    }
  }
}

.timeline_v {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  width: 100%;

  .timeline_item {
    list-style: none;
    display: inline-block;
    width: 100%;
    height: 135px;
    min-height: 135px;

    .item_wrapper {
      height: 100%;
      display: flex;
      position: relative;
      color: #555555;
      border-left: 4px solid #efefee;

      &.last_child {
        height: 1px;
      }
    }

    .item_big_dots {
      position: absolute;
      margin-left: -16px;
      background-color: #efefee;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      text-align: center;
      vertical-align: middle;

      .dots {
        position: absolute;
        left: 0;
        margin-left: 5px;
        bottom: 5px;
        background-color: #888888;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        text-align: center;
        vertical-align: middle;

        &.light {
          background-color: #ffc961;

          &:hover {
            background-color: #ff8244;
          }
        }
      }
    }

    .item_icon {
      padding-left: 15px;
      text-align: center;
      color: #888888;

      &.light {
        color: #ffc961;

        &:hover {
          color: #ff8244;
        }
      }
    }

    .item_content {
      margin-top: 5px;
      text-align: left;
      padding: 0 10px;
      width: 100%;

      .timeline_cg_title {
        font-size: 16px;
        font-weight: bold;
      }
      .timeline_cg_content {
        color: #5475a2;
        font-size: 14px;
        padding: 20px 0;

        .cg_people_count {
          padding-bottom: 10px;
        }
        .cg_member_content {
          .btn_more {
            padding: 7px 24px;
            box-shadow: 0px 2px 0 0 #c48305;
            background-color: #f4b437;
            color: #ffffff;
            border: none;
          }
        }
      }
    }
  }
}
