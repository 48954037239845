@import './mixin.scss';
@import './variables.scss';


html, body{
    font-size: 16px;
    background-color: #eef0f6;    
    height:100%!important;
    min-height:100%!important;
}

a, button{
    cursor: pointer;
    text-decoration: none;  
}

div{
    word-break: break-all;
    box-sizing: border-box;
}

.container{
    text-align: center;
}

div,span,p { 
    font-family: Microsoft JhengHei!important;
}

.box_body{
    background-color: #ffffff;
    border-radius: 8px;        
}

.page {
    display: flex;
    display: -webkit-flex;/* Safari */ 
    flex-direction: column;    
    min-height: 100%;
    width: 100%;
  }

.wrapper{
    position: relative;
    z-index: 1;
    flex:1;
    -webkit-box-flex: 1;/* Safari */ 
    padding-top: 50px;
    padding-bottom: 100px;
   @media (min-width: #{$--desktop}){
        padding-top: 80px;
        padding-bottom: 150px;
     } 

    // min-height: 100%;
    // margin-bottom: 300px;    
    //@media (min-width: #{$--desktop}){
    //     margin-bottom: 165px;
    // } 
}

