@import '../../global/common.scss';

.box{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    border-radius: 50%;    
    color: #f5f9fc;
   @media (min-width: #{$--desktop}){
        width: 50px;
        height: 50px;          
    }

    &.nf{
        background-color: #ff9275;
    }   
    &.audit{
        background-color: #97dbee;
    }
    &.member{
        background-color: #759bcf;        
    }    
}

.nf{
    border: 2px solid #ff9275 !important;
    border-radius: 50%;
}
.audit{
    border: 2px solid #97dbee !important;
    border-radius: 50%;
}
.member{
    border:2px solid #759bcf !important;
    border-radius: 50%;
}