@import '../../global/common.scss';
//notebook size
//@media (min-width: #{$--desktop}){
//     font-size: 36px;            
//   }

.card{
    height: 100%;
    position: relative;
    display: flex;
    display: -webkit-flex;/* Safari */ 
    flex-direction: column;
    flex-wrap:wrap;
  }
  

.card_content{
    width: 100%;
    flex: 1;  
    -webkit-box-flex: 1;/* Safari */ 
    overflow:hidden;
    padding: 11px 30px;    
    
}

.btn_approved{
  display: inline-block;
  border: none;
  padding: 7px 7px;
  width: 97px;    
  border-radius: 18.5px;
  background-color: #ffc961;
  font-size: 18px;
  letter-spacing: 1.58px;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
  font-family: inherit;
 @media (min-width: #{$--desktop}){
      width: 106px;    
      font-size: 21px;      
  }
}    